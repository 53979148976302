<template>
  <div class="articles">
    <homeTop> </homeTop>

    <van-cell v-for="article in articles" :key="article.id" is-link  @click="$router.push(`/article?id=${article.id}`)">
      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <span>{{article.title}}</span>
      </template>
      <!-- <template #right-icon>
        <span style="margin-left:20px;">￥{{Good.price}}元</span>
      </template> -->
      <template #label>
        <span > {{$store.state.date(article.update) }} · {{article.read}}阅读 </span>
      </template>
    </van-cell>
  </div>
</template>

<script>
import { request } from '@/network/request.js'
import homeTop from '@/views/children/home_top'

export default {
  name: 'articles',
  components: {
    homeTop,

  },
  data() {
    return {
      page: {
        // 分页查询的数据
        offset: 1,
        limit: 10,
        where: {},
      },
      articles: [],
    }
  },
  methods: {
    async getPage() {
      let res = await request({
        url: '/article/getPage',
        data: this.page
      })
      this.articles = res.data.data.rows
      console.log(this.articles)
    }
  },
  async created() {
    this.getPage()

  },
}
</script>

<style lang="scss" scoped>
</style>
